<template>
<div class="cart">
  <van-nav-bar
    title="订单结算"
    fixed
    left-arrow
    @click-left="$router.go(-1)"
  />
  
  <div class="sub-title">收货地址</div>
  <van-contact-card v-if="!addressInfo" type="add" @click="$router.push({name: 'address'})" />
  <van-contact-card v-else type="edit" :name="addressInfo.name" :tel="addressInfo.tel" @click="$router.push({name: 'address'})" />
  
  <div class="sub-title">选购商品</div>
  <van-card
    num="1"
    :price="courseInfo.price"
    :desc="courseInfo.title"
    :title="courseInfo.title"
    :thumb="courseInfo.image"
  />
  
  <div class="sub-title"></div>
  <van-cell title="视频号" is-link to="sph" :value="sph.name?sph.name:'设置'" />
  <div class="sub-title"></div>
  <van-cell title="实名认证" is-link to="kyc" :value="kyc.name?kyc.name.substring(0, 1)+'**':'设置'" />
<div class="color-gray2 agree">
    <van-checkbox class="agree-checkbox" v-model="agree" icon-size="16px"></van-checkbox>
    <div>我已阅读
        <span class="color-blue" @click="$router.push({name:'agree',params:{t:'user'}})">《服务协议》</span>,
        <span class="color-blue" @click="$router.push({name:'agree',params:{t:'protect'}})">《个人信息保护协议》</span>,
        <span class="color-blue" @click="$router.push({name:'agree',params:{t:'private'}})">《隐私条款》</span>并同意继续缴费
    </div>
</div>
<!-- <div class="color-gray2 agree">
    <van-checkbox class="agree-checkbox" v-model="agree" icon-size="16px" />
    <div>我已阅读并同意
        <span class="color-blue" @click="$router.push({name:'agree',params:{t:'user'}})">《服务协议》</span>
    </div>
</div>
<div class="color-gray2 agree">
    <van-checkbox class="agree-checkbox" v-model="agree" icon-size="16px" />
    <div>我已阅读并同意
        <span class="color-blue" @click="$router.push({name:'agree',params:{t:'user'}})">《个人信息保护协议》</span>
    </div>
</div>
<div class="color-gray2 agree">
    <van-checkbox class="agree-checkbox" v-model="agree" icon-size="16px" />
    <div>我已阅读并同意
        <span class="color-blue" @click="$router.push({name:'agree',params:{t:'user'}})">《隐私条款》</span>
    </div>
</div> -->

  <van-submit-bar :price="courseInfo.price*100" button-text="提交订单" @submit="onSubmit" />

</div>
  
</template>
<script>
// import cfg from "../config.js";
import { getLocalStorage } from '../utils/funs/storage';
import { Toast } from 'vant';
export default {
  name: "Cart",
  data() {
    return {
      loading: false,
      agree: false,
      courseId: 0,
      courseInfo: {},
      addressInfo: {},
      kyc: {idnum:'',name:''},
      sph: {name:'',sph_id:''},
      payCfg: {},
    };
  },
  computed:{
    
  },
  components:{
  },
  mounted() {
    this.courseInfo = getLocalStorage('cartCourse');
    console.log(this.courseInfo)
    this.getAddress();
    this.getKyc();
    this.getSph();
  },
  methods: {
    getAddress(){
        this.loading = true;
        this.$http.call(this.$http.api.getAddress).then(res=>{
            console.log(res);
            this.loading = false;
            let resp = res.data;
            if(resp.code=='0'){
                this.addressInfo = resp.data;
            }else{
                Toast(resp.msg);
                if(resp.code == '99'){
                  this.$router.push({name: 'login'});
                }
            } 
            
        },function(res){
            console.log('something goes wrong...');
            console.log(res);
        }).then(()=>{
            
        });
    },
    getKyc(){
        this.$http.call(this.$http.api.getKyc).then(res=>{
            console.log(res);
            let resp = res.data;
            if(resp.code=='0'){
              if(resp.data){
                this.kyc = resp.data;
              }
            }else{
                Toast(resp.msg);
                if(resp.code == '99'){
                  this.$router.push({name:'login'});
                }
            } 
        },function(res){
            console.log('something goes wrong...');
            console.log(res);
        });
    },
    getSph(){
        this.$http.call(this.$http.api.getSph).then(res=>{
            let resp = res.data;
            if(resp.code=='0'){
              if(resp.data){
                this.sph = resp.data;
              }
            }else{
                Toast(resp.data);
            } 
        },function(res){
            console.log('something goes wrong...');
            console.log(res);
        });
    },
    onSubmit() {
      console.log('buying...');

      if(!this.addressInfo){
        Toast('请填写收货地址');
        return;
      }

      if(!(this.sph.name && this.sph.sph_id)){
        Toast('请完善视频号信息');
        return;
      }

      if(!(this.kyc.idnum && this.kyc.name)){
        Toast('请完成实名认证');
        return;
      }
      if(!this.agree){
        Toast('请先阅读服务协议,隐私条款及个人信息保护协议');
        return;
      }
      let _this = this;
      this.$http.call(this.$http.api.buy, {
          data: {
              type: _this.courseInfo.ctype,
              id: _this.courseInfo.cid
          }
      }).then(res=>{
          let resp = res.data;
          console.log(resp);
          if(resp.code=='0'){
              // this.courseInfo = resp.data;
              this.payOrder(resp.data.order_id);
          }else{
              Toast(resp.data);
          } 
          
      },function(res){
          console.log('something goes wrong...');
          console.log(res);
      });
    },
    payOrder(order_id){
        let _this = this;
        this.$http.call(this.$http.api.payOrder,{
            data: {
                order_id: order_id,
            }
        }).then(res=>{
            let resp = res.data;
            console.log(resp);
            if(resp.code=='0'){
                _this.payCfg = resp.data.config;
                // 使用WeixinJSBridge唤起微信支付
                if (typeof WeixinJSBridge == "undefined") {
                    if (window.document.addEventListener) {
                        window.document.addEventListener('WeixinJSBridgeReady', this.onBridgeReady, false);
                    } else if (window.document.attachEvent) {
                        window.document.attachEvent('WeixinJSBridgeReady', this.onBridgeReady);
                        window.document.attachEvent('onWeixinJSBridgeReady', this.onBridgeReady);
                    }
                } else {
                    _this.onBridgeReady(_this.payCfg);
                }

            }else{
                Toast(resp.msg);
                if(resp.code == '500'){
                    setTimeout(()=>{
                        this.$router.push({name:'login'});
                    },2000);
                }
            } 
        },function(res){
            console.log('something goes wrong...');
            console.log(res);
        });
    },
    onBridgeReady(cfg) {
        console.log(cfg);
        WeixinJSBridge.invoke('getBrandWCPayRequest', {
            ...cfg
        },
        function(res) {
            if (res.err_msg == "get_brand_wcpay_request:ok") {
                // 使用以上方式判断前端返回,微信团队郑重提示：
                //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
                console.log("支付成功")
            }
        });
    },
  },
};
</script>
<style lang="less" scoped>
.cart {
    padding: 50px 0;
    .sub-title {
      padding: 10px 15px;
      font-size: 13px;
    }
    .agree {
        padding: 15px 15px 0 15px;
        font-size: 13px;
        display: flex;
        height: 20px; 
        line-height: 20px;
        .agree-checkbox {
            padding-right: 10px;
        }
    }
}
</style>
